.admin-footer {
  justify-content: space-between;
}

@media (max-width: 768px) {
  .admin-footer {
    justify-content: center;
    flex-direction: column;
  }
}

.ant-select-selector {
  min-height: 30px !important;
  padding: 5px 10px !important;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-slide img {
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.news-swiper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rich-text-wrapper {
  border: 1px solid #F1F1F1;
  border-radius: 12px;
  padding: 2px;
}

.rich-text-editor {
  padding: 5px 10px !important;
  margin: 0px !important;
  height: fit-content !important;
}

.rich-text-toolbar {
  border-radius: 12px;
  border-right: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}